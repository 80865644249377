import React from "react";

function CallIcon() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="52" height="52" rx="26" fill="#506A47" />
      <path
        d="M16.5 19.5C16.5 28.0604 23.8251 35 32.8611 35C33.2688 35 33.6729 34.9859 34.0732 34.9581C34.5325 34.9262 34.7621 34.9103 34.9712 34.7963C35.1443 34.7019 35.3084 34.5345 35.3952 34.364C35.5 34.1582 35.5 33.9181 35.5 33.438V30.6207C35.5 30.2169 35.5 30.015 35.4298 29.842C35.368 29.6891 35.2672 29.553 35.1368 29.4456C34.9891 29.324 34.7888 29.255 34.3883 29.117L31.0033 27.9509C30.5373 27.7904 30.3042 27.7101 30.0832 27.7237C29.8882 27.7357 29.7007 27.7988 29.5413 27.9058C29.3606 28.0271 29.2331 28.2285 28.9779 28.6314L28.1111 30C25.314 28.7999 23.0465 26.6489 21.7778 24L23.2224 23.1788C23.6476 22.9371 23.8602 22.8163 23.9883 22.6451C24.1013 22.4941 24.1679 22.3164 24.1805 22.1317C24.1949 21.9223 24.1101 21.7015 23.9407 21.26L22.7098 18.0532C22.5642 17.6738 22.4913 17.484 22.363 17.3441C22.2496 17.2205 22.1059 17.1252 21.9446 17.0665C21.7619 17 21.5488 17 21.1226 17H18.1488C17.642 17 17.3886 17 17.1713 17.0993C16.9914 17.1815 16.8147 17.337 16.715 17.501C16.5947 17.6991 16.5778 17.9166 16.5442 18.3517C16.5149 18.7309 16.5 19.1138 16.5 19.5Z"
        stroke="#FBF5E5"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CallIcon;
