import React from "react";

function BurgerIcon() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="52" height="52" rx="26" fill="#506A47" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5 21.0917H16.5V19.1917H35.5V21.0917Z"
        fill="#FBF5E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5 26.95H16.5V25.05H35.5V26.95Z"
        fill="#FBF5E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5 32.8083H16.5V30.9083H35.5V32.8083Z"
        fill="#FBF5E5"
      />
    </svg>
  );
}

export default BurgerIcon;
