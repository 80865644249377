import React from "react";
import Navbar from "../../components/ui-elements/navbar/Navbar";
import styles from "./contacte.module.scss";
import PhoneIcon from "../../components/icons/PhoneIcon";
import RoundFacebookIcon from "../../components/icons/RoundFacebookIcon";
import RoundInstagramIcon from "../../components/icons/RoundInstagramIcon";
import {Link} from "react-router-dom";

function Contacte() {
  return (
    <>
      <Navbar scrolling={false} />
      <div className={styles.contacteContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.title}>Contacte</div>
          <div className={styles.secondBox}>
            <div className={styles.numberText}>+373 686 86 123</div>
            <div className={styles.nameText}>Pentru rezervări</div>
            <a href="tel:+37368686123" className={styles.callBtn}>
              <div className={styles.callNow}>SUNĂ ACUM</div>
              <PhoneIcon />
            </a>
            <div className={styles.socialBox}>
              <Link to="https://www.facebook.com/vatici/">
                <RoundFacebookIcon />
              </Link>
              <Link to="https://www.instagram.com/vatici_resort/?hl=en">
                <RoundInstagramIcon />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contacte;
