import React from "react";
import styles from "./thirdResort.module.scss";
import PhoneIcon from "../../../icons/PhoneIcon";
const decoration3 = require("../../../../assets/img/thirdblock/decoration3.png");
const photo3 = require("../../../../assets/img/thirdblock/photo3.png");
const decoration4 = require("../../../../assets/img/thirdblock/decoration4.png");
const photo4 = require("../../../../assets/img/thirdblock/photo4.png");


function ThirdResortComponent() {
  return (
      <div style={{overflowX: "clip", overflowY: "visible"}}>
    <div className={styles.thirdResortContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.contentWrapper}>
          <div className={styles.contentBox}>
            <div className={styles.title}>Conexiune cu natura</div>
            <div className={styles.description}>
              Relaxează-te într-un cadru natural minunat, observă păsările
              cântătoare și animalele din împrejurimile resortului nostru din
              Vatici.
            </div>
            <a href="tel:+37368686123" className={styles.callBtn}>
              <div className={styles.callNow}>SUNĂ ACUM</div>
              <PhoneIcon />
            </a>
          </div>
          <div className={styles.imageBox}>
            <img
              className={styles.decorationOne}
              style={{ position: "absolute", top: -300, left: -220 }}
              src={decoration3}
              alt="decoration"
            />
            <img
              className={styles.image}
              src={photo3}
              style={{
                position: "absolute",
                top: -170,
                left: -30,
                zIndex: 10,
              }}
              alt="beci"
            />
          </div>
        </div>
        <div className={`${styles.contentWrapper} ${styles.column}`}>
          <div className={styles.imageBox}>
            <img
              className={styles.decorationTwo}
              style={{ position: "absolute", top: -500, left: -150 }}
              src={decoration4}
              alt="decoration"
            />
            <img
              className={styles.image}
              src={photo4}
              style={{
                position: "absolute",
                top: -210,
                left: 20,
                zIndex: 10,
              }}
              alt="albine"
            />
          </div>
          <div className={styles.contentBox}>
            <div className={styles.title}>Paradisul fructelor</div>
            <div className={styles.description}>
              Descoperă o oază de savoare cu o varietate de fructe proaspete și
              delicioase cultivate în livada resortului nostru din Vatici.
            </div>
            <a href="tel:+37368686123" className={styles.callBtn}>
              <div className={styles.callNow}>SUNĂ ACUM</div>
              <PhoneIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
        </div>
  );
}

export default ThirdResortComponent;
