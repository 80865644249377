import React from "react";

function InstagramIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.36966 0.122699C4.74177 0.196149 3.33081 0.594146 2.19149 1.72866C1.04819 2.86913 0.655141 4.28568 0.581508 5.89663C0.535736 6.90211 0.268086 14.4983 1.04422 16.4904C1.56761 17.8343 2.59844 18.8677 3.95468 19.3927C4.58752 19.6389 5.30994 19.8055 6.36966 19.8541C15.2305 20.2551 18.5151 20.0368 19.9002 16.4904C20.146 15.8591 20.3151 15.1373 20.3619 14.0802C20.7669 5.19668 20.2962 3.27112 18.7519 1.72866C17.527 0.506796 16.0862 -0.324951 6.36966 0.122699ZM6.45123 18.0675C5.48107 18.0238 4.9547 17.8622 4.60345 17.7262C3.71986 17.3828 3.05617 16.7218 2.71487 15.8434C2.12382 14.3297 2.31985 7.14049 2.37259 5.97719C2.42433 4.83771 2.65517 3.79631 3.45916 2.99233C4.4542 1.99975 5.73978 1.51329 14.4931 1.90834C15.6354 1.95995 16.6792 2.19033 17.4852 2.99233C18.4803 3.9849 18.9738 5.28037 18.5718 14.0001C18.528 14.9679 18.3658 15.493 18.2295 15.8434C17.329 18.1511 15.2573 18.4715 6.45123 18.0675ZM14.5897 4.68959C14.5897 5.34668 15.124 5.881 15.7837 5.881C16.4434 5.881 16.9788 5.34668 16.9788 4.68959C16.9788 4.03251 16.4434 3.49868 15.7837 3.49868C15.124 3.49868 14.5897 4.03251 14.5897 4.68959ZM5.36267 9.98794C5.36267 12.8029 7.65027 15.0851 10.4722 15.0851C13.2941 15.0851 15.5817 12.8029 15.5817 9.98794C15.5817 7.173 13.2941 4.89223 10.4722 4.89223C7.65027 4.89223 5.36267 7.173 5.36267 9.98794ZM7.15573 9.98794C7.15573 8.1616 8.64033 6.67983 10.4722 6.67983C12.3041 6.67983 13.7887 8.1616 13.7887 9.98794C13.7887 11.8153 12.3041 13.2975 10.4722 13.2975C8.64033 13.2975 7.15573 11.8153 7.15573 9.98794Z"
        fill="#473500"
      />
    </svg>
  );
}

export default InstagramIcon;
