import React from "react";

function GreenFacebookLogo() {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 11.0604C21.5 5.50473 17.0223 1 11.5 1C5.97768 1 1.5 5.50473 1.5 11.0604C1.5 16.0816 5.15625 20.2437 9.9375 20.9991V13.9694H7.39777V11.0604H9.9375V8.84397C9.9375 6.32303 11.4308 4.9294 13.7147 4.9294C14.8089 4.9294 15.9536 5.12611 15.9536 5.12611V7.60214H14.692C13.4504 7.60214 13.0621 8.37733 13.0621 9.17408V11.0604H15.8353L15.3924 13.9694H13.0625V21C17.8438 20.245 21.5 16.083 21.5 11.0604V11.0604Z"
        stroke="#083C00"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default GreenFacebookLogo;
