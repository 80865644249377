import React from "react";

function GreenInstagramLogo() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.36966 0.122729C4.74177 0.196179 3.33081 0.594176 2.19149 1.72869C1.04819 2.86916 0.655141 4.28571 0.581508 5.89666C0.535736 6.90214 0.268086 14.4983 1.04422 16.4904C1.56761 17.8343 2.59844 18.8677 3.95468 19.3928C4.58752 19.6389 5.30994 19.8055 6.36966 19.8542C15.2305 20.2552 18.5151 20.0369 19.9002 16.4904C20.146 15.8591 20.3151 15.1373 20.3619 14.0803C20.7669 5.19671 20.2962 3.27115 18.7519 1.72869C17.527 0.506826 16.0862 -0.324921 6.36966 0.122729ZM6.45123 18.0676C5.48107 18.0239 4.9547 17.8622 4.60345 17.7262C3.71986 17.3828 3.05617 16.7219 2.71487 15.8434C2.12382 14.3298 2.31985 7.14052 2.37259 5.97722C2.42433 4.83774 2.65517 3.79634 3.45916 2.99236C4.4542 1.99978 5.73978 1.51332 14.4931 1.90837C15.6354 1.95998 16.6792 2.19036 17.4852 2.99236C18.4803 3.98493 18.9738 5.2804 18.5718 14.0002C18.528 14.9679 18.3658 15.4931 18.2295 15.8434C17.329 18.1512 15.2573 18.4715 6.45123 18.0676ZM14.5897 4.68962C14.5897 5.34671 15.124 5.88103 15.7837 5.88103C16.4434 5.88103 16.9788 5.34671 16.9788 4.68962C16.9788 4.03254 16.4434 3.49871 15.7837 3.49871C15.124 3.49871 14.5897 4.03254 14.5897 4.68962ZM5.36267 9.98797C5.36267 12.8029 7.65027 15.0851 10.4722 15.0851C13.2941 15.0851 15.5817 12.8029 15.5817 9.98797C15.5817 7.17303 13.2941 4.89226 10.4722 4.89226C7.65027 4.89226 5.36267 7.17303 5.36267 9.98797ZM7.15573 9.98797C7.15573 8.16164 8.64033 6.67986 10.4722 6.67986C12.3041 6.67986 13.7887 8.16164 13.7887 9.98797C13.7887 11.8153 12.3041 13.2975 10.4722 13.2975C8.64033 13.2975 7.15573 11.8153 7.15573 9.98797Z"
        fill="#083C00"
      />
    </svg>
  );
}

export default GreenInstagramLogo;
