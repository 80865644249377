import React from "react";
import styles from "./downbar.module.scss";
import DarkGreenLogo from "../../../icons/DarkGreenSmallLogo";
import LinkedInLogo from "../../../icons/LinkedInLogo";
import PhoneIcon from "../../../icons/PhoneIcon";
import GreenInstagramIcon from "../../../icons/GreenInstagramLogo";
import GreenFacebookIcon from "../../../icons/GreenFacebookLogo";
import DarkGreenSmallLogo from "../../../icons/DarkGreenSmallLogo";
import {Link} from "react-router-dom";

function Downbar() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className={styles.downbarContainer}>
        <div className={styles.downbarContent}>
          <div className={styles.contentBox}>
            <Link to="#" onClick={scrollToTop}>
              <DarkGreenLogo />
            </Link>
            <div className={styles.linkedinBox}>
              <Link
                to="https://www.linkedin.com/in/nichita-cebotari/"
                target="_blank"
                className={styles.linkedinCard}
              >
                <div className={styles.linkedinCardText}>
                  Designed by: Nichita Cebotari
                </div>
                <LinkedInLogo />
              </Link>
              <Link
                to="https://www.linkedin.com/in/antonio-lupu/"
                target="_blank"
                className={styles.linkedinCard}
              >
                <div className={styles.linkedinCardText}>
                  Front-end by: Antonio Lupu
                </div>
                <LinkedInLogo />
              </Link>
            </div>
          </div>
          <div className={styles.contentBox} style={{ maxWidth: 420 }}>
            <div
              className={styles.btns}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className={styles.callBtnBox} style={{ alignSelf: "end" }}>
                <a href="tel:+37368686123" className={styles.callBtn}>
                  <div className={styles.callNow}>SUNĂ ACUM</div>
                  <PhoneIcon />
                </a>
              </div>
              <div className={styles.btnBox}>
                <div className={styles.menuBtns}>
                  <Link to="/despre" className={styles.menuBtn}>
                    Despre
                  </Link>
                  <Link to="/contacte" className={styles.menuBtn}>
                    Contacte
                  </Link>
                  <div className={styles.socialBox}>
                    <Link
                      to="https://www.instagram.com/vatici_resort/?hl=en"
                      target="_blank"
                      className={styles.socialBtn}
                    >
                      <GreenInstagramIcon />
                    </Link>
                    <Link
                      to="https://www.facebook.com/vatici/"
                      target="_blank"
                      className={styles.socialBtn}
                    >
                      <GreenFacebookIcon />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.downbarContainer2}>
        <div className={styles.leftBox}>
          <Link to="#" onClick={scrollToTop}>
            <DarkGreenSmallLogo />
          </Link>
          <a href="tel:+37368686123" className={styles.callBtn}>
            <div className={styles.callNow}>SUNĂ ACUM</div>
            <PhoneIcon />
          </a>
          <Link
            to="https://www.linkedin.com/in/nichita-cebotari/"
            target="_blank"
            className={styles.linkedinCard}
          >
            <div className={styles.linkedinCardText}>
              Designed by: Nichita Cebotari
            </div>
            <LinkedInLogo />
          </Link>
        </div>
        <div className={styles.rightBox}>
          <div className={styles.menuBtns}>
            <Link to="/despre" className={styles.menuBtn}>
              Despre
            </Link>
            <Link to="/contacte" className={styles.menuBtn}>
              Contacte
            </Link>
            <div className={styles.socialBox}>
              <Link
                to="https://www.instagram.com/vatici_resort/?hl=en"
                target="_blank"
                className={styles.socialBtn}
              >
                <GreenInstagramIcon />
              </Link>
              <Link
                to="https://www.facebook.com/vatici/"
                target="_blank"
                className={styles.socialBtn}
              >
                <GreenFacebookIcon />
              </Link>
            </div>
          </div>
          <Link
            style={{ alignSelf: "end" }}
            to="https://www.linkedin.com/in/antonio-lupu/"
            target="_blank"
            className={styles.linkedinCard}
          >
            <div className={styles.linkedinCardText}>
              Front-end by: Antonio Lupu
            </div>
            <LinkedInLogo />
          </Link>
        </div>
      </div>
    </>
  );
}

export default Downbar;
