import React from "react";

function LinkedInLogo() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.27022V15.7298C0 17.0002 0.99976 18 2.27022 18H15.7298C16.3516 18 16.9458 17.7025 17.3246 17.3246C17.7302 16.9189 18 16.4052 18 15.7298V2.27022C18 0.99976 17.0002 0 15.7298 0H2.27022C1.59477 0 1.08106 0.270696 0.675443 0.675443C0.296642 1.05424 0 1.64839 0 2.27022ZM2.43281 4.05352C2.43281 3.18868 3.13592 2.43194 4.00077 2.43194C4.89242 2.43194 5.62235 3.18868 5.62235 4.05352C5.62235 4.94518 4.89242 5.6483 4.00077 5.6483C3.13592 5.6483 2.43281 4.94518 2.43281 4.05352ZM6.78384 15.1616V6.91875C6.78384 6.72935 6.97324 6.56763 7.10815 6.56763H9.40518C9.7295 6.56763 9.7295 6.94643 9.7295 7.18945C10.3781 6.54082 11.2162 6.37823 12.081 6.37823C14.1895 6.37823 15.5404 7.37799 15.5404 9.59458V15.1624C15.5404 15.3518 15.3778 15.5136 15.2161 15.5136H12.8377C12.6483 15.5136 12.5134 15.3242 12.5134 15.1624V10.1351C12.5134 9.29707 12.2704 8.83784 11.3243 8.83784C10.1351 8.83784 9.8376 9.62139 9.8376 10.6756V15.1624C9.8376 15.3518 9.6482 15.5136 9.4588 15.5136H7.10729C6.97237 15.5136 6.78297 15.3242 6.78297 15.1624L6.78384 15.1616ZM2.48643 15.1616V6.91875C2.48643 6.72935 2.67583 6.56763 2.81074 6.56763H5.16225C5.37847 6.56763 5.51338 6.70254 5.51338 6.91875V15.1616C5.51338 15.351 5.35079 15.5127 5.16225 15.5127H2.81074C2.64815 15.5127 2.48643 15.3233 2.48643 15.1616Z"
        fill="#083C00"
      />
    </svg>
  );
}

export default LinkedInLogo;
