import React from "react";

function PhoneIcon() {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2.72222C1 10.3315 7.16852 16.5 14.7778 16.5C15.1211 16.5 15.4614 16.4875 15.7985 16.4628C16.1852 16.4344 16.3786 16.4203 16.5547 16.3189C16.7004 16.235 16.8387 16.0862 16.9117 15.9347C17 15.7517 17 15.5383 17 15.1116V12.6073C17 12.2484 17 12.0689 16.9409 11.9151C16.8888 11.7792 16.804 11.6582 16.6941 11.5628C16.5698 11.4547 16.4011 11.3933 16.0638 11.2707L13.2133 10.2341C12.8209 10.0915 12.6246 10.0201 12.4385 10.0322C12.2743 10.0428 12.1164 10.0989 11.9821 10.194C11.83 10.3019 11.7226 10.4809 11.5077 10.839L10.7778 12.0556C8.42231 10.9888 6.5128 9.0768 5.44444 6.72222L6.661 5.99228C7.01907 5.77745 7.1981 5.67003 7.30596 5.51783C7.40107 5.38363 7.45716 5.22566 7.46782 5.06151C7.47991 4.87535 7.40853 4.67914 7.26587 4.28671L6.22932 1.43619C6.10667 1.0989 6.04534 0.930249 5.93726 0.805867C5.84179 0.695991 5.72079 0.611245 5.58491 0.559067C5.43108 0.5 5.25163 0.5 4.89273 0.5H2.38845C1.96167 0.5 1.74828 0.5 1.56532 0.588222C1.41378 0.661298 1.26501 0.799564 1.18107 0.94536C1.07972 1.1214 1.06556 1.31477 1.03724 1.70154C1.01256 2.03854 1 2.37892 1 2.72222Z"
        stroke="#E9F2D0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PhoneIcon;
