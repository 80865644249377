import React from "react";
import Navbar from "../../components/ui-elements/navbar/Navbar";
import styles from "../contacte/contacte.module.scss";
import PhoneIcon from "../../components/icons/PhoneIcon";
import RoundFacebookIcon from "../../components/icons/RoundFacebookIcon";
import RoundInstagramIcon from "../../components/icons/RoundInstagramIcon";
import {Link} from "react-router-dom";

function Despre() {
  return (
    <>
      <Navbar scrolling={false} />
      <div className={styles.contacteContainer}>
        <div className={styles.contentContainer} style={{ maxHeight: 500 }}>
          <div className={styles.title}>Despre noi</div>
          <div className={styles.secondBox} style={{ maxHeight: 330 }}>
            <div
              className={styles.descriptionText}
              style={{ fontSize: 20, textTransform: "unset", lineHeight: 1.2 }}
            >
              Vatici - un rai ascuns în inima Moldovei, unde te poți bucura de
              liniște, natură și o atmosferă relaxantă.
            </div>
            <a href="tel:+37368686123" className={styles.callBtn}>
              <div className={styles.callNow}>SUNĂ ACUM</div>
              <PhoneIcon />
            </a>
            <div className={styles.socialBox}>
              <Link to="https://www.facebook.com/vatici/">
                <RoundFacebookIcon />
              </Link>
              <Link to="https://www.instagram.com/vatici_resort/?hl=en">
                <RoundInstagramIcon />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Despre;
