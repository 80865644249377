import React from "react";

function RoundInstagramIcon() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.5" cy="20" r="20" fill="#506A47" />
      <rect x="9.5" y="9.0001" width="22" height="22" rx="5" fill="#FFFAE3" />
      <path
        d="M27.5 12.0001C26.9477 12.0001 26.5 12.4478 26.5 13.0001C26.5 13.5524 26.9477 14.0001 27.5 14.0001C28.0523 14.0001 28.5 13.5524 28.5 13.0001C28.5 12.4478 28.0523 12.0001 27.5 12.0001Z"
        fill="#506A47"
      />
      <circle cx="20.5" cy="20.0001" r="5" stroke="#506A47" strokeWidth="2" />
    </svg>
  );
}

export default RoundInstagramIcon;
