import React from "react";

function CloseIcon() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="52" height="52" rx="26" fill="#506A47" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0915 33.4352L18.6565 20.0002L20 18.6567L33.435 32.0917L32.0915 33.4352Z"
        fill="#FBF5E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.344 19.9083L19.9089 33.3434L18.5654 31.9998L32.0005 18.5648L33.344 19.9083Z"
        fill="#FBF5E5"
      />
    </svg>
  );
}

export default CloseIcon;
