import { Routes, Route } from "react-router-dom";
import Landing from "./pages/home/page";
import Contacte from "./pages/contacte/page";
import Despre from "./pages/despre/page"

function App() {
  return (
      <>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/contacte" element={<Contacte />} />
          <Route path="/despre" element={<Despre />}/>
        </Routes>
      </>
  );
}

export default App;
