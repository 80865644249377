import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import styles from "./page.module.scss";
import Navbar from "../../components/ui-elements/navbar/Navbar";
import FirstResortComponent from "../../components/ui-elements/blocks/first/FirstResortComponent";
import SecondResortComponent from "../../components/ui-elements/blocks/second/SecondResortComponent";
import loading from "../../assets/lottie/loading.json";
import GreenLogo from "../../components/icons/GreenLogo";
import ThirdResortComponent from "../../components/ui-elements/blocks/third/ThirdResortComponent";
import FourthResortComponent from "../../components/ui-elements/blocks/fourth/FourthResortComponent";

export default function Landing() {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const loadingOverlay = document.getElementById(
      "loadingOverlay",
    ) as HTMLElement;
    loadingOverlay.style.opacity = "1";

    setTimeout(() => {
      loadingOverlay.style.opacity = "0";
      setTimeout(() => {
        loadingOverlay.style.display = "none";
      }, 1000);
    }, 2000);
  }, []);

  return (
    <>
      <div id="loadingOverlay" className={styles.blurOverlay}>
        <div
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            maxWidth: 275,
            width: "100%",
            height: 180,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              gap: 15,
              color: "#E9F2D0",
            }}
          >
            <GreenLogo />
            <div
              style={{
                fontFamily: "Playfair Display",
                fontStyle: "italic",
                fontSize: 20,
                fontWeight: 400,
              }}
            >
              Loading...
            </div>
          </div>
          <Lottie
            animationData={loading}
            width={200}
            style={{ maxHeight: 100, height: "100%" }}
          />
        </div>
      </div>
      <div className={styles.landingContainer}>
        <Navbar scrolling={scrolling} />
        <FirstResortComponent scrolling={scrolling} />
        <SecondResortComponent />
        <ThirdResortComponent />
        <FourthResortComponent />
      </div>
    </>
  );
}
