import React from "react";
import Lottie from "lottie-react";
import styles from "./firstResort.module.scss";
import mouseJson from "../../../../assets/lottie/mouse.json";

type Props = {
  scrolling: boolean;
};
function FirstResortComponent({ scrolling }: Props) {
  return (
    <div className={styles.resortImageContent}>
      <div className={styles.contentWrapper}>
        <div className={styles.contentBox}>
          <div className={styles.campingResortText}>Camping & Resort</div>
          <div className={styles.description}>
            Vatici este o comună din raionul Orhei, Moldova. Este formată din
            trei sate: Curchi, Tabăra și Vatici. Pe teritoriul comunei se află
            Mănăstirea Ortodoxă Curchi.
          </div>
        </div>
        <div className={styles.lottieContainer}>
          <div
            className={`${styles.lottieBox} ${scrolling ? styles.hidden : ""}`}
          >
            <Lottie animationData={mouseJson} loop style={{ width: "30px" }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstResortComponent;
