import React from "react";

function BackBtn() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="52"
        height="52"
        rx="26"
        transform="matrix(-1 0 0 1 52 0)"
        fill="#506A47"
      />
      <path
        d="M34 26H18M18 26L19.7124 25.5524C22.2381 24.8922 24 22.6106 24 20M18 26L19.7124 26.4476C22.2381 27.1078 24 29.3894 24 32"
        stroke="#FFFAE3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BackBtn;
