import React from "react";

function RoundFacebookIcon() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.5" cy="20" r="20" fill="#506A47" />
      <path
        d="M20.5 9.0001C14.45 9.0001 9.5 13.9589 9.5 20.0664C9.5 25.5885 13.526 30.1718 18.784 31.0001V23.2692H15.99V20.0664H18.784V17.6256C18.784 14.8535 20.423 13.3294 22.942 13.3294C24.141 13.3294 25.395 13.5393 25.395 13.5393V16.2672H24.009C22.645 16.2672 22.216 17.1176 22.216 17.9901V20.0664H25.274L24.779 23.2692H22.216V31.0001C24.808 30.589 27.1684 29.2612 28.8709 27.2561C30.5733 25.2512 31.5059 22.7011 31.5 20.0664C31.5 13.9589 26.55 9.0001 20.5 9.0001Z"
        fill="#FFFAE3"
      />
    </svg>
  );
}

export default RoundFacebookIcon;
