import React from "react";
import { Carousel } from "react-responsive-carousel";
import styles from "./fourthResort.module.scss";
import Downbar from "../../../ui-elements/blocks/downbar/Downbar";
import PhoneIcon from "../../../icons/PhoneIcon";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NextBtn from "../../../icons/NextBtn";
import BackBtn from "../../../icons/BackBtn";

const photo1 = require("../../../../assets/img/fourthblock/photo1.png");
const photo2 = require("../../../../assets/img/fourthblock/photo2.png");
const photo3 = require("../../../../assets/img/fourthblock/photo3.png");
const photo4 = require("../../../../assets/img/fourthblock/photo4.png");
const photo5 = require("../../../../assets/img/fourthblock/photo5.png");

type RenderArrowFunction = (
  onClickHandler: () => void,
  hasArrow: boolean,
  label: string,
) => JSX.Element;

function FourthResortComponent() {
  const images = [photo1, photo2, photo3, photo4, photo5];
  const renderCustomPrevArrow: RenderArrowFunction = (
    onClickHandler,
    hasPrev,
    label,
  ) => (
    <button
      className={`${styles.customArrow} ${styles.customPrevArrow}`}
      onClick={onClickHandler}
      disabled={!hasPrev}
      aria-label={label}
    >
      <BackBtn />
    </button>
  );

  const renderCustomNextArrow: RenderArrowFunction = (
    onClickHandler,
    hasNext,
    label,
  ) => (
    <button
      className={`${styles.customArrow} ${styles.customNextArrow}`}
      onClick={onClickHandler}
      disabled={!hasNext}
      aria-label={label}
    >
      <NextBtn />
    </button>
  );

  return (
    <div className={styles.fourthResortContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.contentWrapper}>
          <div className={styles.contentBox}>
            <div className={styles.title}>VATICI CAMPING & RESORT</div>
            <div className={styles.description}>
              Bucură-te de tradiții și ospitalitate moldovenească la resortul
              nostru din Vatici. Explorează peisajele pitorești ale Orheiului și
              bucură-te de drumeții, ciclism sau alte activități în aer liber.
            </div>
            <a href="tel:+37368686123" className={styles.callBtn}>
              <div className={styles.callNow}>SUNĂ ACUM</div>
              <PhoneIcon />
            </a>
          </div>
        </div>
        <div className={styles.carousel} style={{ touchAction: "pan-y pinch-zoom" }}>
          <Carousel
            emulateTouch
            animationHandler="slide"
            showThumbs={false}
            infiniteLoop
            showStatus={false}
            showArrows
            autoPlay
            stopOnHover
            dynamicHeight
            swipeScrollTolerance={20}
            renderArrowPrev={renderCustomPrevArrow}
            renderArrowNext={renderCustomNextArrow}
          >
            {images.map((imageUrl, index) => (
              <div>
                <img
                  className={styles.image}
                  src={imageUrl}
                  alt={`${index + 1}`}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <Downbar />
    </div>
  );
}

export default FourthResortComponent;
